import React from "react";
import { Button } from "@rent_avail/controls";
import { Box } from "@rent_avail/core";
const PurpleLinkButton = ({ children, ...props }) => (React.createElement(Box, { as: Button, "aria-haspopup": props.ariaHaspopup, "aria-controls": props.ariaControls, "aria-expanded": props.ariaExpanded, onClick: props.onClick, variant: "unstyled", sx: {
        background: "transparent",
        borderStyle: "none !important",
        color: "purple_700",
        fontWeight: "700 !important",
        textTransform: "none !important",
        width: ["100%", "100%", "auto"],
        p: "0 !important",
        "&:hover": {
            backgroundColor: "none !important",
            color: ({ colors }) => `${colors.purple_700} !important`,
        },
        span: {
            fontSize: "1.333rem !important",
        },
    } }, children));
export default PurpleLinkButton;
