import React, { useContext } from "react"
import { ExternalLink, AlertCircle, EyeOff } from "react-feather"
import { Box, Flex } from "@rent_avail/core"
import { noop } from "@rent_avail/utils"

import * as styles from "../ListingsStyles.scss"
import { SyndicatedListingPartnerLogos } from "../config"
import { PartnerItemContext, PartnersListContext } from "./ListingStatusContexts"

const BadgeIcon = () => {
  const { hideEyeOff } = useContext(PartnersListContext)
  const { listingStatus } = useContext(PartnerItemContext)

  if (listingStatus === "Published") {
    return <ExternalLink className="navy-blue-300" />
  }
  if (listingStatus === "Failed") {
    return <AlertCircle className="red" />
  }
  return !hideEyeOff ? <EyeOff className="light-gray-500" /> : null
}

const PartnerItemBadge = () => {
  const { unit } = useContext(PartnersListContext)
  const { partner, listingStatus, listingErrorMessage, partnerDisplayName, isPromoted } =
    useContext(PartnerItemContext)
  const listingStatusUrl = unit[`listing_status_url_${partner}`]
  const partnerHasPublished = !!listingStatusUrl
  const published = listingStatus === "Published"

  const getListingStatusWithErrors = () => {
    if (listingStatus === "Failed" || listingErrorMessage) {
      return <span className="red">{listingStatus}</span>
    }
    if (isPromoted) {
      return (
        <Box as="span" sx={{ color: "purple_700", fontWeight: 700 }}>
          Promoted
        </Box>
      )
    }
    if (published) {
      return <span className="navy-blue-500">{listingStatus}</span>
    }
    if (listingStatus) {
      return listingStatus.toLowerCase().humanize()
    }
    return "Off Market"
  }

  return (
    <Box
      as="a"
      href={partnerHasPublished ? listingStatusUrl : "#"}
      target={partnerHasPublished ? "_blank" : "_self"}
      rel="noreferrer noopener"
      className={`${styles.pillButton} margin-0p5`}
      data-cy={`listings-${partner}-display-badge`}
      sx={{ cursor: published ? "pointer" : "default" }}
      onClick={partnerHasPublished ? noop : (e) => e.preventDefault()}
    >
      <Flex sx={{ alignItems: "center" }}>
        <img
          src={SyndicatedListingPartnerLogos[partner]}
          alt={partnerDisplayName}
          style={{ width: 24, height: "auto", marginRight: 10 }}
        />
        <Box sx={{ marginRight: 10 }}>
          <strong>{partnerDisplayName.humanize()}</strong> | {getListingStatusWithErrors()}
        </Box>
      </Flex>
      <BadgeIcon listingStatus={listingStatus} />
    </Box>
  )
}

export default PartnerItemBadge
