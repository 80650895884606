import React from "react";
import { useParams } from "react-router-dom";
import { Box, Flex } from "@rent_avail/core";
import { ArrowRight, Zap } from "@rent_avail/icons";
import Tag from "@rent_avail/tag";
import { Heading, Text } from "@rent_avail/typography";
import { trackingAssist } from "utils";
import PromotedListingsLearnMoreDialog from "./PromotedListingsLearnMoreDialog";
import PurpleLinkButton from "./purchase/PurpleLinkButton";
const PromoteListingLearnMoreBanner = () => {
    const [open, setOpen] = React.useState(false);
    const { id: unitId } = useParams();
    const handleLearnMore = () => {
        trackingAssist.trackEvent("Promoted Listing Clicked", {
            "Unit ID": unitId,
            "Modal Version": "Off Market Listing",
            Type: "Education",
        });
        setOpen(true);
    };
    const handleToggle = () => setOpen(!open);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { as: "section", "aria-labelledby": "promoted-listings-title-id", sx: {
                alignItems: "center",
                bg: "purple_100",
                flexWrap: ["wrap", "wrap", null],
                gap: 2,
                justifyContent: "space-between",
                ml: [-1, -1, 0, 0],
                mr: [-1, -1, 0, 0],
                mb: -2,
                width: ["100vw", "100vw", "100%"],
                p: "2rem",
            } },
            React.createElement(Flex, { sx: {
                    flexDirection: ["column", "column", "row"],
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                    width: "100%",
                } },
                React.createElement(Flex, { sx: { alignItems: "flex-start", gap: [1, 1, 0], zIndex: 1 } },
                    React.createElement(Box, { as: Zap, sx: {
                            color: "purple_700",
                            fill: "currentColor",
                            flexShrink: 0,
                            mr: [0, 0, "1rem"],
                        }, size: "2rem" }),
                    React.createElement(Box, null,
                        React.createElement(Flex, { sx: { gap: 1, pb: "0.5rem" } },
                            React.createElement(Heading, { as: "h4", id: "promoted-listings-title-id", color: "purple_700", fontSize: "1.5rem", fontWeight: "800" }, "Introducing Promoted Listings"),
                            React.createElement(Tag, { alignSelf: "flex-start", bg: "blue_500", color: "white" }, "Beta")),
                        React.createElement(Text, { fontSize: "1.5rem", fontWeight: 400 }, "Next time you publish, boost your listings exposure to rent your place faster."))),
                React.createElement(Box, { sx: { alignSelf: "center", marginLeft: "auto" } },
                    React.createElement(PurpleLinkButton, { ariaHaspopup: "dialog", ariaControls: "promoted-listings-learn-more-dialog-id", ariaExpanded: open, onClick: handleLearnMore },
                        React.createElement(Flex, { sx: {
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "0.5rem",
                                fontSize: "1.5rem",
                                mt: ["1rem", "1rem", 0],
                            } },
                            React.createElement(Text, null, "Learn How It Works"),
                            React.createElement(ArrowRight, { size: 24 })))))),
        React.createElement(PromotedListingsLearnMoreDialog, { open: open, toggle: handleToggle })));
};
export default PromoteListingLearnMoreBanner;
