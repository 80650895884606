import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Modal } from "react-bootstrap"
import { Form } from "reactiverecord"
import Sugar from "sugar"
import {
  ModalHeader,
  Button,
  Text,
  AddressInput,
} from "ui_components"
import { userType } from "types/userType"

import { ValidatedInput, ValidatedDateInput } from "ui_components/Input"

const propTypes = {
  CURRENT_USER: userType.isRequired,
}

class ConfirmWithProfile extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      show: !props.CURRENT_USER.contactInfoComplete,
    }
    this.confirm = this.confirm.bind(this)
    this.deny = this.deny.bind(this)
  }

  validateBirthDate = (fields) => {
    // when an invalid birth_date input is "entered" and a user hits Enter button
    // it will be submitted without a validation
    const validation = new Promise((resolve, reject) => {
      const date = Sugar.Date.create(fields.birth_date)
      if (Sugar.Date.isValid(date)) {
        resolve(fields)
      } else {
        reject(Error("not a valid date"))
      }
    })
    return validation
  }


  confirm() {
    const { props } = this
    this.closeModal(props.confirm)
  }

  deny() {
    const { props } = this
    this.closeModal(props.deny)
  }

  closeModal(cb) {
    this.setState({ show: false })
    setTimeout(cb, 250) /* For modal animation to end */
  }

  render() {
    const {
      props, state, confirm, deny,
    } = this
    return (
      <Modal show={state.show} onHide={deny}>
        <ModalHeader title="Contact Information" onHide={deny} />
        <div className="padding-1-lr margin-1-t">
          <Text title>Almost Done!</Text>
          <Text>{props.title || "We just need your contact information for the lease."}</Text>
        </div>
        <Form
          for={props.CURRENT_USER}
          beforeSave={this.validateBirthDate}
          afterSave={confirm}
          builder={AddressInput.builder}
        >
          {(fields) => (
            <div className="padding-1">
              <div className="margin-2-b loop tight loop--left-emphasis padding-1">
                <Text bold>Your Current Address</Text>
                <AddressInput {...fields.addressFields} />
              </div>
              <div className="margin-2-b loop tight loop--left-emphasis padding-1">
                <ValidatedInput {...fields.address2} labelText="Unit number (if applicable)" />
              </div>
              <div className="margin-2-b loop tight loop--left-emphasis padding-1">
                <ValidatedDateInput
                  {...fields.birth_date}
                  labelText="Date of Birth"
                  datePicker
                  birthDate
                  datePickerOptions={{
                    defaultDate: new Date("1988"),
                    maxDate: new Date(),
                  }}
                  required
                />
              </div>
              <div className="right margin-1-t">
                <Button {...fields.submit} create>
                  Save
                </Button>
              </div>
            </div>
          )}
        </Form>
      </Modal>
    )
  }
}

ConfirmWithProfile.propTypes = propTypes

export default (props) => new Promise((resolve, reject) => {
  if (props.CURRENT_USER.contactInfoComplete) {
    return resolve()
  }

  const reactRoot = document.createElement("div")
  document.body.appendChild(reactRoot)

  const handleCleanup = (decision) => {
    ReactDOM.unmountComponentAtNode(reactRoot)
    document.body.removeChild(reactRoot)
    decision.call(this)
  }
  const confirm = handleCleanup.bind(this, resolve)
  const deny = handleCleanup.bind(this, reject)

  ReactDOM.render(
    <ConfirmWithProfile confirm={confirm} deny={deny} {...props} />,
    reactRoot,
  )
})
