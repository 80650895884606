import { Model as FluteModel } from "redux-flute"
import { Validator, Model } from "reactiverecord"
import moment from "moment"
import { googleMapsApiKey } from "imported-vars"
import { NAME_REGEX } from "utils/regex-constants"

import "./ReactiveRecord/BackgroundCheck"
import "./ReactiveRecord/BackgroundCheckPayment"
import "./ReactiveRecord/PreviousRentReportingPayment"
import "./ReactiveRecord/Challenge"
import "./ReactiveRecord/FinancialInstitution"
import "./ReactiveRecord/ListingSearch"
import "./ReactiveRecord/Verification"
import "./ReactiveRecord/PaymentIndexLease"
import "./ReactiveRecord/IndexLease"
import Applicant from "./ReactiveRecord/Applicant"
import Application from "./ReactiveRecord/Application"
import BankAccount from "./ReactiveRecord/BankAccount"
import Building from "./ReactiveRecord/Building"
import Charge from "./ReactiveRecord/Charge"
import ChargeSeries from "./ReactiveRecord/ChargeSeries"
import Comment from "./ReactiveRecord/Comment"
import CreditCard from "./ReactiveRecord/CreditCard"
import CustomApplicationQuestion from "./ReactiveRecord/CustomApplicationQuestion"
import EmploymentHistory from "./ReactiveRecord/EmploymentHistory"
import IncomeVerification from "./ReactiveRecord/IncomeVerification"
import Invoice from "./ReactiveRecord/Invoice"
import Landlord from "./ReactiveRecord/Landlord"
import LandlordInvitation from "./ReactiveRecord/LandlordInvitation"
import "./ReactiveRecord/LateChargeReport"
import Lease from "./ReactiveRecord/Lease"
import LeaseClause from "./ReactiveRecord/LeaseClause"
import LeaseInvitation from "./ReactiveRecord/LeaseInvitation"
import "./ReactiveRecord/LeasePaymentPattern"
import LeaseResource from "./ReactiveRecord/LeaseResource"
import LeaseRule from "./ReactiveRecord/LeaseRule"
import PartnerClick from "./ReactiveRecord/PartnerClick"
import Payment from "./ReactiveRecord/Payment"
import PaymentSeries from "./ReactiveRecord/PaymentSeries"
import RentalHistory from "./ReactiveRecord/RentalHistory"
import RenterProfile from "./ReactiveRecord/RenterProfile"
import "./ReactiveRecord/ResendTenantInvite"
import "./ReactiveRecord/SendLateChargeReminderEmail"
import "./ReactiveRecord/Showing"
import "./ReactiveRecord/ShowingBlock"
import Signature from "./ReactiveRecord/Signature"
import StandardCustomApplicationQuestion from "./ReactiveRecord/StandardCustomApplicationQuestion"
import SubscriptionPlan from "./ReactiveRecord/SubscriptionPlan"
import Tenant from "./ReactiveRecord/Tenant"
import "./ReactiveRecord/TenantStatus"
import Unit from "./ReactiveRecord/Unit"
import "./ReactiveRecord/UnitStats"
import User from "./ReactiveRecord/User"
import "./ReactiveRecord/UserSearch"
import "./ReactiveRecord/DiscussionTopic"
import "./ReactiveRecord/PublicImages"
import "./ReactiveRecord/RoutingNumber"

Model.MAPS_API_KEY = googleMapsApiKey
Model.MAPS_STYLE_STRING =
  "&style=feature:all%7Celement:geometry.fill%7Cweight:2.00&style=feature:all%7Celement:geometry.stroke%7Ccolor:0xc5c5c6&style=feature:all%7Celement:labels.text%7Cvisibility:on&style=feature:administrative.province%7Celement:labels.text.fill%7Ccolor:0x7c95b0&style=feature:administrative.province%7Celement:labels.text.stroke%7Ccolor:0xf8f8f9&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0x0f3e6f&style=feature:administrative.locality%7Celement:labels.text.stroke%7Ccolor:0xf8f8f9&style=feature:administrative.neighborhood%7Celement:labels.text.fill%7Ccolor:0x7c95b0&style=feature:administrative.neighborhood%7Celement:labels.text.stroke%7Ccolor:0xf8f8f9&style=feature:landscape%7Celement:all%7Ccolor:0xf8f8f9&style=feature:landscape%7Celement:geometry.fill%7Ccolor:0xf8f8f9&style=feature:landscape.man_made%7Celement:geometry.fill%7Ccolor:0xf8f8f9&style=feature:poi%7Celement:all%7Cvisibility:off&style=feature:road%7Celement:all%7Csaturation:-100%7Clightness:45&style=feature:road%7Celement:geometry.fill%7Ccolor:0xf3f3f3&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x6e6e6f&style=feature:road%7Celement:labels.text.stroke%7Ccolor:0xf8f8f9&style=feature:road.highway%7Celement:all%7Cvisibility:simplified&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x7c95b0&style=feature:road.arterial%7Celement:labels.text.stroke%7Ccolor:0xf8f8f9&style=feature:road.arterial%7Celement:labels.icon%7Cvisibility:on&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x7c95b0&style=feature:road.local%7Celement:labels.text.stroke%7Ccolor:0xf8f8f9&style=feature:transit%7Celement:all%7Cvisibility:on&style=feature:water%7Celement:all%7Ccolor:0xe9edf1%7Cvisibility:on&style=feature:water%7Celement:geometry.fill%7Ccolor:0xe9edf1&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x0b2e51&style=feature:water%7Celement:labels.text.stroke%7Ccolor:0xf8f8f9%7C"
FluteModel.MAPS_API_KEY = googleMapsApiKey

Applicant.validations = {
  cover_photo: {
    attachment_content_type: [
      {
        message: "Cover photo is the wrong file type.",
        content_type: /image/,
      },
    ],
  },
  full_name: {
    presence: [
      {
        message: "A full name is required.",
      },
    ],
  },
  email: {
    presence: [
      {
        message: "An email is required.",
      },
    ],
    email: [
      {
        message: "Please use a valid email.",
      },
    ],
  },
  phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  about_pets: {
    length: [
      {
        messages: {
          maximum: "Your pets summary is too long (maximum is 255 characters).",
        },
        maximum: 255,
      },
    ],
  },
  current_income_cents: {
    numericality: [
      {
        messages: {
          numericality: "Current income cents must be a number.",
          less_than: "Current income cents must be less than 1000000000.",
        },
        allow_blank: true,
        less_than: 1000000000,
      },
    ],
  },
  alternate_phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  ec_phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
}
Application.validations = {
  user: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
  unit_id: {
    presence: [
      {
        message: "A unit is required.",
      },
    ],
  },
  applicants: {
    presence: [
      {
        message: "An applicants is required.",
      },
    ],
  },
  move_in: {
    presence: [
      {
        message: "A move in is required.",
      },
    ],
  },
  start_date: {
    presence: [
      {
        message: "A start date is required.",
      },
    ],
  },
  end_date: {
    presence: [
      {
        message: "An end date is required.",
      },
    ],
  },
  status: {
    inclusion: [
      {
        message: "Status is not included in the list.",
        in: ["Requested", "Received", "Accepted", "Rejected"],
      },
    ],
  },
}
BankAccount.validations = {
  account_number: {
    presence: [
      {
        message: "An account number is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Account number must be a number.",
        },
      },
    ],
    length: [
      {
        messages: {
          minimum: "Account number is too short (minimum is 4 characters).",
          maximum: "Account number is too long (maximum is 17 characters).",
        },
        minimum: 4,
        maximum: 17,
      },
    ],
  },
  routing_number: {
    presence: [
      {
        message: "A routing number is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Please enter a 9-digit number.",
        },
      },
    ],
    length: [
      {
        messages: {
          is: "Routing number is the wrong length (should be 9 characters).",
        },
        is: 9,
      },
    ],
  },
  user: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
  name: {
    presence: [
      {
        message: "A name is required.",
      },
    ],
  },
  ach_terms: {
    acceptance: [
      {
        message: "Ach terms must be accepted",
        accept: true,
      },
    ],
  },
}
Building.validations = {
  user_id: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
  address1: {
    presence: [
      {
        message: "A street address is required.",
      },
    ],
  },
  city: {
    presence: [
      {
        message: "A city is required.",
      },
    ],
  },
  state: {
    presence: [
      {
        message: "A state is required.",
      },
    ],
    inclusion: [
      {
        message: "State is not included in the list.",
        in: [
          "Alabama",
          "AL",
          "Alaska",
          "AK",
          "Arizona",
          "AZ",
          "Arkansas",
          "AR",
          "California",
          "CA",
          "Colorado",
          "CO",
          "Connecticut",
          "CT",
          "Delaware",
          "DE",
          "District Of Columbia",
          "DC",
          "Florida",
          "FL",
          "Georgia",
          "GA",
          "Hawaii",
          "HI",
          "Idaho",
          "ID",
          "Illinois",
          "IL",
          "Indiana",
          "IN",
          "Iowa",
          "IA",
          "Kansas",
          "KS",
          "Kentucky",
          "KY",
          "Louisiana",
          "LA",
          "Maine",
          "ME",
          "Maryland",
          "MD",
          "Massachusetts",
          "MA",
          "Michigan",
          "MI",
          "Minnesota",
          "MN",
          "Mississippi",
          "MS",
          "Missouri",
          "MO",
          "Montana",
          "MT",
          "Nebraska",
          "NE",
          "Nevada",
          "NV",
          "New Hampshire",
          "NH",
          "New Jersey",
          "NJ",
          "New Mexico",
          "NM",
          "New York",
          "NY",
          "North Carolina",
          "NC",
          "North Dakota",
          "ND",
          "Ohio",
          "OH",
          "Oklahoma",
          "OK",
          "Oregon",
          "OR",
          "Pennsylvania",
          "PA",
          "Puerto Rico",
          "PR",
          "Rhode Island",
          "RI",
          "South Carolina",
          "SC",
          "South Dakota",
          "SD",
          "Tennessee",
          "TN",
          "Texas",
          "TX",
          "Utah",
          "UT",
          "Vermont",
          "VT",
          "Virginia",
          "VA",
          "Washington",
          "WA",
          "West Virginia",
          "WV",
          "Wisconsin",
          "WI",
          "Wyoming",
          "WY",
        ],
      },
    ],
  },
  zip: {
    presence: [
      {
        message: "A zip is required.",
      },
    ],
    zip: [
      {
        message: "Please use a 5-digit ZIP code.",
      },
    ],
  },
}
Charge.validations = {
  amount: {
    presence: [
      {
        message: "An amount is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Amount must be a number.",
          greater_than: "Amount must be greater than 0.01.",
        },
        greater_than: 0.01,
      },
    ],
  },
  description: {
    presence: [
      {
        message: "A description is required.",
      },
    ],
  },
  due_date: {
    presence: [
      {
        message: "A due date is required.",
      },
    ],
    date: [
      {
        message: "Try YYYY-MM-DD.",
      },
    ],
  },
  for: {
    presence: [
      {
        message: "A for is required.",
      },
    ],
    inclusion: [
      {
        message: "For is not included in the list.",
        in: ["security_deposit", "rent", "late_fee", "fee", "recurring_fee"],
      },
    ],
  },
  lease: {
    presence: [
      {
        message: "A lease is required.",
      },
    ],
  },
  user: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
  amount_cents: {
    numericality: [
      {
        messages: {
          numericality: "Amount cents must be a number.",
          only_integer: "Amount cents must be an integer.",
        },
        only_integer: true,
      },
      {
        messages: {
          numericality: "Amount cents must be a number.",
          greater_than: "Amount cents must be greater than 0.",
        },
        greater_than: 0,
      },
    ],
  },
}
ChargeSeries.validations = {
  lease_id: {
    presence: [
      {
        message: "A lease is required.",
      },
    ],
  },
  user_id: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
  amount_cents: {
    presence: [
      {
        message: "Amount is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Amount must be a number.",
          greater_than: "Amount must be greater than 1.",
        },
        greater_than: 1,
      },
    ],
  },
  amount: {
    presence: [
      {
        message: "Amount is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Amount must be a number.",
          greater_than: "Amount must be greater than 0.01.",
        },
        greater_than: 0.01,
      },
    ],
  },
  start_date: {
    presence: [
      {
        message: "Start date is required.",
      },
    ],
    date: [
      {
        message: "Try YYYY-MM-DD.",
      },
    ],
  },
  end_date: {
    presence: [
      {
        message: "End date is required.",
      },
    ],
    date: [
      {
        message: "Try YYYY-MM-DD.",
      },
    ],
  },
}
Comment.validations = {
  content: {
    presence: [
      {
        message: "A message is required.",
      },
    ],
  },
  user_id: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
}
CreditCard.validations = {
  card_last_four: {
    presence: [
      {
        message: "A card last four is required.",
      },
    ],
  },
  expiration_month: {
    presence: [
      {
        message: "An expiration month is required.",
      },
    ],
  },
  expiration_year: {
    presence: [
      {
        message: "An expiration year is required.",
      },
    ],
  },
  user_id: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
  card_type: {
    presence: [
      {
        message: "A card type is required.",
      },
    ],
  },
  stripe_card_fingerprints: {
    presence: [
      {
        message: "A stripe card fingerprints is required.",
      },
    ],
  },
  stripe_tokens: {
    presence: [
      {
        message: "A stripe tokens is required.",
      },
    ],
  },
}
CustomApplicationQuestion.validations = {
  question_text: {
    presence: [
      {
        message: "A question is required.",
      },
    ],
    length: [
      {
        messages: {
          maximum: "Question is too long (maximum is 255 characters).",
        },
        maximum: 255,
      },
    ],
  },
}
EmploymentHistory.validations = {
  company: {
    presence: [
      {
        message: "A company is required.",
      },
    ],
  },
  job_title: {
    presence: [
      {
        message: "A job title is required.",
      },
    ],
  },
  supervisor_name: {
    presence: [
      {
        message: "A supervisor name is required.",
      },
    ],
    format: [
      {
        message: "Special characters are not accepted in the name.",
        with: NAME_REGEX,
        allow_blank: true, // Need to be true in order for "presence" validator to work
      },
    ],
  },
  supervisor_email: {
    presence: [
      {
        message: "A supervisor email is required.",
      },
    ],
    email: [
      {
        message: "Please use a valid email.",
      },
    ],
  },
  start_date: {
    presence: [
      {
        message: "A start date is required.",
      },
    ],
  },
  supervisor_phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  zip: {
    zip: [
      {
        message: "Please use a 5-digit ZIP code.",
      },
    ],
  },
  salary: {
    format: [
      {
        message: "Please enter a valid salary.",
        with: /^[\$]?[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
        allow_blank: true,
      },
    ],
  },
}
IncomeVerification.validations = {
  attachment: {
    presence: [
      {
        message: "An attachment is required.",
      },
    ],
    attachment_file_type_ignorance: [
      {
        message:
          "translation missing: en.activerecord.errors.models.income_verification.attributes.attachment.attachment_file_type_ignorance",
      },
    ],
  },
}
Invoice.validations = {
  user: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
  invoice_items: {
    length: [
      {
        messages: {
          minimum: "Must have at least one invoice item",
        },
        minimum: 1,
      },
    ],
  },
}
LandlordInvitation.validations = {
  tenant_email: {
    presence: [
      {
        message: "A tenant email is required.",
      },
    ],
    email: [
      {
        message: "Please use a valid email.",
      },
    ],
  },
  landlord_name: {
    presence: [
      {
        message: "A landlord name is required.",
      },
    ],
    format: [
      {
        message: "Special characters are not accepted in the name.",
        with: NAME_REGEX,
        allow_blank: true, // Need to be true in order for "presence" validator to work
      },
    ],
  },
  landlord_email: {
    presence: [
      {
        message: "A landlord email is required.",
      },
    ],
    email: [
      {
        message: "Please use a valid email.",
      },
    ],
  },
  tenant_message: {
    length: [
      {
        messages: {
          maximum: "Tenant message is too long (maximum is 255 characters).",
        },
        maximum: 255,
      },
    ],
  },
  landlord_phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  start_date: {
    date: [
      {
        message: "Try YYYY-MM-DD.",
        allow_blank: true,
      },
    ],
  },
  end_date: {
    date: [
      {
        message: "Try YYYY-MM-DD.",
        allow_blank: true,
      },
    ],
  },
}
Lease.validations = {
  attachment: {
    attachment_file_type_ignorance: [
      {
        message:
          "translation missing: en.activerecord.errors.models.lease.attributes.attachment.attachment_file_type_ignorance",
      },
    ],
  },
  unit: {
    presence: [
      {
        message: "A unit is required.",
      },
    ],
  },
  landlord: {
    presence: [
      {
        message: "A landlord is required.",
      },
    ],
  },
  start_date: {
    presence: [
      {
        message: "A start date is required.",
      },
    ],
    date: [
      {
        message: "Try YYYY-MM-DD.",
      },
    ],
  },
  end_date: {
    presence: [
      {
        message: "An end date is required.",
      },
    ],
    date: [
      {
        message: "Try YYYY-MM-DD.",
      },
    ],
  },
  rent: {
    presence: [
      {
        message: "A rent amount is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Rent must be a number.",
          greater_than: "Rent must be greater than 0.",
        },
        greater_than: 0,
      },
    ],
  },
  security_deposit_data: {
    presence: [
      {
        message: "A security deposit amount is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Security deposit amount must be a number.",
          greater_than_or_equal_to: "Security deposit should be equal to or more than 0.",
        },
        allow_blank: true,
        greater_than_or_equal_to: 0,
      },
    ],
  },
  parking: {
    presence: [
      {
        message: "A parking is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Parking must be a number.",
          greater_than_or_equal_to: "Parking fee should be equal to or more than 0.",
        },
        allow_blank: true,
        greater_than_or_equal_to: 0,
      },
    ],
  },
  move_in_fee: {
    presence: [
      {
        message: "A move in fee is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Move in fee must be a number.",
          greater_than_or_equal_to: "Move in fee should be equal to or more than 0.",
        },
        allow_blank: true,
        greater_than_or_equal_to: 0,
      },
    ],
  },
  move_out_fee: {
    presence: [
      {
        message: "A move out fee is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Move out fee must be a number.",
          greater_than_or_equal_to: "Move out fee should be equal to or more than 0.",
        },
        allow_blank: true,
        greater_than_or_equal_to: 0,
      },
    ],
  },
  prorated_rent: {
    numericality: [
      {
        messages: {
          numericality: "Prorated rent must be a number.",
          greater_than_or_equal_to: "Prorated rent should be equal to or more than 0.",
        },
        allow_blank: true,
        greater_than_or_equal_to: 0,
      },
    ],
  },
  dog_charge: {
    numericality: [
      {
        messages: {
          numericality: "Dog charge must be a number.",
          greater_than_or_equal_to: "Pet rent should be equal to or more than 0.",
        },
        allow_blank: true,
        greater_than_or_equal_to: 0,
      },
    ],
  },
  cat_charge: {
    numericality: [
      {
        messages: {
          numericality: "Cat charge must be a number.",
        },
        allow_blank: true,
      },
    ],
  },
  pet_deposit: {
    numericality: [
      {
        messages: {
          numericality: "Pet deposit must be a number.",
          greater_than_or_equal_to: "Pet deposit should be equal to or more than 0.",
        },
        allow_blank: true,
        greater_than_or_equal_to: 0,
      },
    ],
  },
  late_fee: {
    numericality: [
      {
        messages: {
          numericality: "Late fee must be a number.",
          greater_than_or_equal_to: "Late rent fee should be equal to or more than 0.",
        },
        allow_blank: true,
        greater_than_or_equal_to: 0,
      },
    ],
  },
  lead_explanation: {
    length: [
      {
        messages: {
          maximum: "Lead explanation is too long (maximum is 250 characters).",
        },
        allow_blank: true,
        maximum: 250,
      },
    ],
  },
  radon_explanation: {
    length: [
      {
        messages: {
          maximum: "Radon explanation is too long (maximum is 250 characters).",
        },
        allow_blank: true,
        maximum: 250,
      },
    ],
  },
  mold_explanation: {
    length: [
      {
        messages: {
          maximum: "Mold explanation is too long (maximum is 250 characters).",
        },
        allow_blank: true,
        maximum: 250,
      },
    ],
  },
  asbestos_explanation: {
    length: [
      {
        messages: {
          maximum: "Asbestos explanation is too long (maximum is 250 characters).",
        },
        allow_blank: true,
        maximum: 250,
      },
    ],
  },
  bedbugs_explanation: {
    length: [
      {
        messages: {
          maximum: "Bedbugs explanation is too long (maximum is 250 characters).",
        },
        allow_blank: true,
        maximum: 250,
      },
    ],
  },
}
LeaseClause.validations = {
  lease_id: {
    presence: [
      {
        message: "A lease is required.",
      },
    ],
  },
  name: {
    presence: [
      {
        message: "A name is required.",
      },
    ],
    length: [
      {
        messages: {
          maximum: "Name is too long (maximum is 250 characters).",
        },
        allow_blank: true,
        maximum: 250,
      },
    ],
  },
  clause: {
    presence: [
      {
        message: "A clause is required.",
      },
    ],
  },
}
LeaseInvitation.validations = {
  name: {
    format: [
      {
        message: "Special characters are not accepted in the name.",
        with: NAME_REGEX,
        allow_blank: true, // Need to be true in order for "presence" validator to work
      },
    ],
    presence: [
      {
        message: "A name is required.",
      },
    ],
  },
  email: {
    email: [
      {
        message: "Please use a valid email.",
      },
    ],
    uniqueness: [
      {
        message: "The renter's email must not be the same as another on this lease.",
        case_sensitive: true,
        scope: {
          lease_id: null,
        },
      },
    ],
  },
  phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
    uniqueness: [
      {
        message: "You cannot have duplicate phone numbers for multiple tenants on this lease.",
        case_sensitive: true,
        scope: {
          lease_id: null,
        },
      },
    ],
  },
}
LeaseResource.validations = {
  attachment: {
    attachment_file_type_ignorance: [
      {
        message:
          "translation missing: en.activerecord.errors.models.lease_resource.attributes.attachment.attachment_file_type_ignorance",
      },
    ],
    attachment_presence: [
      {
        message:
          "translation missing: en.activerecord.errors.models.lease_resource.attributes.attachment.attachment_presence",
      },
    ],
  },
}
LeaseRule.validations = {
  lease_id: {
    presence: [
      {
        message: "A lease is required.",
      },
    ],
  },
  rule: {
    presence: [
      {
        message: "A rule is required.",
      },
    ],
  },
}
PartnerClick.validations = {
  account_type: {
    inclusion: [
      {
        message: "Account type is not included in the list.",
        in: ["tenant", "landlord"],
      },
    ],
  },
  partner_name: {
    presence: [
      {
        message: "A partner name is required.",
      },
    ],
  },
  email: {
    presence: [
      {
        message: "An email is required.",
      },
    ],
    email: [
      {
        message: "Please use a valid email.",
      },
    ],
  },
  first_name: {
    presence: [
      {
        message: "A first name is required.",
      },
    ],
  },
  last_name: {
    presence: [
      {
        message: "A last name is required.",
      },
    ],
  },
  phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
}
Payment.validations = {
  confirm: {
    acceptance: [
      {
        message: "Please confirm your transaction to continue",
        accept: true,
        nil: false,
      },
    ],
  },
  charge: {
    presence: [
      {
        message: "A charge is required.",
      },
    ],
  },
  from_user: {
    presence: [
      {
        message: "A from user is required.",
      },
    ],
  },
  to_user: {
    presence: [
      {
        message: "A to user is required.",
      },
    ],
  },
  amount: {
    presence: [
      {
        message: "An amount is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Amount must be a number.",
          greater_than: "Amount must be greater than 0.01.",
        },
        greater_than: 0.01,
      },
    ],
  },
  pay_date: {
    presence: [
      {
        message: "A pay date is required.",
      },
    ],
    date: [
      {
        message: "Try YYYY-MM-DD.",
      },
    ],
  },
  from_bank_account: {
    presence: [
      {
        message: "A from bank account is required.",
      },
    ],
  },
  manually_received_from: {
    presence: [
      {
        message: "Please enter a name.",
      },
    ],
  },
  received_date: {
    /* Manually added validation (client-side only) */
    presence: [
      {
        message: "This is required.",
      },
    ],
    date: [
      {
        message: "Try YYYY-MM-DD.",
        allow_blank: true,
      },
    ],
  },
}
PaymentSeries.validations = {
  charge_series_id: {
    presence: [
      {
        message: "A charge series is required.",
      },
    ],
  },
  amount_cents: {
    presence: [
      {
        message: "Amount is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Amount must be a number.",
          greater_than: "Amount must be greater than 1.",
        },
        greater_than: 1,
      },
    ],
  },
  amount: {
    presence: [
      {
        message: "Amount is required.",
      },
    ],
    numericality: [
      {
        messages: {
          numericality: "Amount must be a number.",
          greater_than: "Amount must be greater than 0.01.",
        },
        greater_than: 0.01,
      },
    ],
  },
  pay_on_day: {
    presence: [
      {
        message: "Pay on day is required.",
      },
    ],
    date: [
      {
        message: "Try YYYY-MM-DD.",
      },
    ],
  },
  from_user: {
    presence: [
      {
        message: "Please indicate who this payment is from.",
      },
    ],
  },
}
RentalHistory.validations = {
  phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  start_date: {
    presence: [
      {
        message: "A start date is required.",
      },
    ],
  },
  address1: {
    presence: [
      {
        message: "A street address is required.",
      },
    ],
  },
  city: {
    presence: [
      {
        message: "A city is required.",
      },
    ],
    format: [
      {
        message: "Please use only letters and spaces (No periods or special characters).",
        with: /^[A-Za-z\s]*$/,
      },
    ],
  },
  state: {
    presence: [
      {
        message: "A state is required.",
      },
    ],
  },
  zip: {
    presence: [
      {
        message: "A zip is required.",
      },
    ],
    zip: [
      {
        message: "Please use a 5-digit ZIP code.",
      },
    ],
  },
  landlord_name: {
    presence: [
      {
        message: "A landlord name is required.",
      },
    ],
    format: [
      {
        message: "Special characters are not accepted in the name.",
        with: NAME_REGEX,
      },
    ],
  },
  email: {
    presence: [
      {
        message: "An email is required.",
      },
    ],
    email: [
      {
        message: "Please use a valid email.",
      },
    ],
  },
  ownership_type: {
    inclusion: [
      {
        message: "Ownership type is not included in the list.",
        in: ["rental", "owner"],
      },
    ],
  },
}
Signature.validations = {
  name: {
    format: [
      {
        message: "Special characters are not accepted in the name.",
        with: NAME_REGEX,
      },
    ],
    presence: [
      {
        message: "Please enter your name.",
      },
    ],
  },
  password: {
    presence: [
      {
        message: "You must enter your current password.",
      },
    ],
  },
  confirm: {
    acceptance: [
      {
        message: "You must agree to Avail terms and conditions.",
        accept: true,
      },
    ],
  },
}
StandardCustomApplicationQuestion.validations = {
  user_id: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
  question_text: {
    presence: [
      {
        message: "A question is required.",
      },
    ],
    length: [
      {
        messages: {
          maximum: "Question is too long (maximum is 255 characters).",
        },
        maximum: 255,
      },
    ],
  },
}
SubscriptionPlan.validations = {
  name: {
    presence: [
      {
        message: "A name is required.",
      },
    ],
  },
  description: {
    presence: [
      {
        message: "A description is required.",
      },
    ],
  },
  validity_level: {
    presence: [
      {
        message: "A validity level is required.",
      },
    ],
    inclusion: [
      {
        message: "Validity level is not included in the list.",
        in: ["landlord", "admin", "tenant"],
      },
    ],
  },
  max_units: {
    numericality: [
      {
        messages: {
          numericality: "Max units must be a number.",
          greater_than_or_equal_to: "Max units must be greater than or equal to 0.",
        },
        allow_blank: true,
        greater_than_or_equal_to: 0,
      },
    ],
  },
  ach_fee_cents: {
    numericality: [
      {
        messages: {
          numericality: "Ach fee cents must be a number.",
          greater_than_or_equal_to: "Ach fee cents must be greater than or equal to 0.",
        },
        greater_than_or_equal_to: 0,
      },
    ],
  },
  monthly_base_cents: {
    numericality: [
      {
        messages: {
          numericality: "Monthly base cents must be a number.",
          greater_than_or_equal_to: "Monthly base cents must be greater than or equal to 0.",
        },
        greater_than_or_equal_to: 0,
      },
    ],
  },
  monthly_per_unit_cents: {
    numericality: [
      {
        messages: {
          numericality: "Monthly per unit cents must be a number.",
          greater_than_or_equal_to: "Monthly per unit cents must be greater than or equal to 0.",
        },
        greater_than_or_equal_to: 0,
      },
    ],
  },
  lease_set_up_cents: {
    numericality: [
      {
        messages: {
          numericality: "Lease set up cents must be a number.",
          greater_than_or_equal_to: "Lease set up cents must be greater than or equal to 0.",
        },
        greater_than_or_equal_to: 0,
      },
    ],
  },
  background_check_fee_cents: {
    numericality: [
      {
        messages: {
          numericality: "Background check fee cents must be a number.",
          greater_than_or_equal_to:
            "Background check fee cents must be greater than or equal to 0.",
        },
        greater_than_or_equal_to: 0,
      },
    ],
  },
}
Unit.validations = {
  user_id: {
    presence: [
      {
        message: "A user is required.",
      },
    ],
  },
  rent: {
    presence: [
      {
        message: "A rent amount is required.",
      },
    ],
  },
  unit_type: {
    presence: [
      {
        message: "A unit type is required.",
      },
    ],
  },
  key_replacement_charge: {
    presence: [
      {
        message: "A key replacement charge is required.",
      },
    ],
  },
  min_lease_length: {
    numericality: [
      {
        messages: {
          numericality: "Min lease length must be a number.",
        },
        allow_blank: true,
      },
    ],
  },
  bedrooms: {
    numericality: [
      {
        messages: {
          numericality: "Bedrooms must be a number.",
        },
        allow_blank: true,
      },
    ],
  },
  bathrooms: {
    numericality: [
      {
        messages: {
          numericality: "Please enter just a number. (It can be something like 2.5 for baths.)",
        },
        allow_blank: true,
      },
    ],
  },
  sqft: {
    numericality: [
      {
        messages: {
          numericality: "Sqft must be a number.",
        },
        allow_blank: true,
      },
    ],
  },
  posting_title: {
    length: [
      {
        messages: {
          maximum: "Posting title is too long (maximum is 255 characters).",
        },
        maximum: 255,
      },
    ],
  },
  laundry: {
    inclusion: [
      {
        message: "Laundry is not included in the list.",
        in: ["in-unit", "in-building", "hookup in-unit", "offsite"],
      },
    ],
  },
}
User.validations = {
  password: {
    confirmation: [
      {
        message: "Password confirmation doesn't match.",
      },
    ],
    presence: [
      {
        message: "A password is required.",
      },
    ],
    length: [
      {
        messages: {
          minimum: "Password is too short (minimum is 10 characters).",
          maximum: "Password is too long (maximum is 40 characters).",
        },
        minimum: 10,
        maximum: 40,
      },
    ],
    format: [
      {
        message: "Your password must contain at least one letter.",
        with: /^.*(?=.*[a-z]).*$/i,
      },
      {
        message: "Your password must contain at least one number.",
        with: /^.*(?=.*[0-9]).*$/,
      },
    ],
  },
  password_digest: {
    presence: [
      {
        message: "A password digest is required.",
      },
    ],
  },
  user_photo: {
    attachment_file_type_ignorance: [
      {
        message:
          "translation missing: en.activerecord.errors.models.user.attributes.user_photo.attachment_file_type_ignorance",
      },
    ],
  },
  agree_to_legal: {
    acceptance: [
      {
        message: "The agree to legal must be accepted.",
        accept: true,
      },
    ],
  },
  phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  phone2: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  company_phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  company_phone2: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  urgent_maintenance_phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  name: {
    presence: [
      {
        message: "A name is required.",
      },
    ],
    format: [
      {
        message: "Special characters are not accepted in the name.",
        with: NAME_REGEX,
      },
    ],
  },
  email: {
    presence: [
      {
        message: "An email is required.",
      },
    ],
    email: [
      {
        message: "Please use a valid email.",
      },
    ],
    uniqueness: [
      {
        message: "has already been taken",
        case_sensitive: true,
      },
      {
        message: "has already been taken",
        case_sensitive: true,
      },
    ],
  },
  proxy_level: {
    presence: [
      {
        message: "Please select an account type.",
      },
    ],
  },
  level: {
    presence: [
      {
        message: "A level is required.",
      },
    ],
  },
  promo: {
    format: [
      {
        message: "Coupon code format is incorrect. Format should be XX-XXXX.",
        with: /^[a-zA-Z0-9]{2}-[a-zA-Z0-9]{4}$/,
        allow_blank: true,
      },
    ],
  },
}
Landlord.validations = {
  password: {
    confirmation: [
      {
        message: "Password confirmation doesn't match.",
      },
    ],
    presence: [
      {
        message: "A password is required.",
      },
    ],
    length: [
      {
        messages: {
          minimum: "Password is too short (minimum is 10 characters).",
          maximum: "Password is too long (maximum is 40 characters).",
        },
        minimum: 10,
        maximum: 40,
      },
    ],
    format: [
      {
        message: "Your password must contain at least one letter.",
        with: /^.*(?=.*[a-z]).*$/i,
      },
      {
        message: "Your password must contain at least one number.",
        with: /^.*(?=.*[0-9]).*$/,
      },
    ],
  },
  password_digest: {
    presence: [
      {
        message: "A password digest is required.",
      },
    ],
  },
  user_photo: {
    attachment_file_type_ignorance: [
      {
        message:
          "translation missing: en.activerecord.errors.models.user.attributes.user_photo.attachment_file_type_ignorance",
      },
    ],
  },
  agree_to_legal: {
    acceptance: [
      {
        message: "The agree to legal must be accepted.",
        accept: true,
      },
    ],
  },
  phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  phone2: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  company_phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  company_phone2: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  urgent_maintenance_phone: {
    phone_number: [
      {
        message: "Please use a valid U.S. phone number (000-000-0000)",
      },
    ],
  },
  name: {
    presence: [
      {
        message: "A name is required.",
      },
    ],
    format: [
      {
        message: "Special characters are not accepted in the name.",
        with: NAME_REGEX,
      },
    ],
  },
  email: {
    presence: [
      {
        message: "An email is required.",
      },
    ],
    email: [
      {
        message: "Please use a valid email.",
      },
    ],
    uniqueness: [
      {
        message: "has already been taken",
        case_sensitive: true,
      },
      {
        message: "has already been taken",
        case_sensitive: true,
      },
    ],
  },
  proxy_level: {
    presence: [
      {
        message: "Please select an account type.",
      },
    ],
  },
  level: {
    presence: [
      {
        message: "A level is required.",
      },
    ],
  },
  promo: {
    format: [
      {
        message: "Coupon code format is incorrect. Format should be XX-XXXX.",
        with: /^[a-zA-Z0-9]{2}-[a-zA-Z0-9]{4}$/,
        allow_blank: true,
      },
    ],
  },
}
Tenant.validations = Landlord.validations

RenterProfile.validations = Applicant.validations
RenterProfile.validations.birth_date = {
  presence: [{ message: "A birth date is required." }],
  date: [{ message: "Try YYYY-MM-DD." }],
}
RenterProfile.validations.phone.presence = [{ message: "A phone is required." }]

ReactClientSideValidations.remoteValidators.email = {
  validate(element, options, callback) {
    if (!element.val()) {
      return callback(null)
    }
    // eslint-disable-next-line no-useless-escape
    const message = /^([^@\(\)<\>\,\;\:\\\/\"\[\]\{\}\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i.test(
      element.val(),
    )
      ? null
      : options.message
    callback(message)
  },
}

Validator.validators.remote.email = function (value, options, form, attribute, callback) {
  if (!value) {
    return callback(null)
  }
  // eslint-disable-next-line no-useless-escape
  const message = /^([^@\(\)<\>\,\;\:\\\/\"\[\]\{\}\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i.test(value)
    ? null
    : options.message
  callback(message)
}

ReactClientSideValidations.localValidators.zip = {
  validate(element, options) {
    if (!element.val()) {
      return false
    }
    return /^\d{5}$/.test(element.val()) ? false : options.message
  },
}

Validator.validators.remote.zip = function (value, options, form, attribute, callback) {
  if (!value) {
    return callback(null)
  }
  const message = /^\d{5}$/.test(value) ? null : options.message
  callback(message)
}

/* ReactClientSideValidations.localValidators.date already exists */
Validator.validators.remote.date = function (value, options, form, attribute, callback) {
  if (!value) {
    return callback(null)
  }
  const message = moment(value, "YYYY-MM-DD").isValid() ? null : options.message
  callback(message)
}

ReactClientSideValidations.localValidators.phone_number = {
  validate(element, options) {
    if (!element.val()) {
      return false
    }
    let cleanedValue = element.val().replace(/\D/g, "")
    if (cleanedValue.charAt(0) === "1") {
      cleanedValue = cleanedValue.substr(1)
    }
    element.value = cleanedValue
    return cleanedValue.length === 10 ? false : options.message
  },
}

Validator.validators.remote.phone_number = function (value, options, form, attribute, callback) {
  if (!value) {
    return callback(null)
  }
  let cleanedValue = value.replace(/\D/g, "")
  if (cleanedValue.charAt(0) === "1") {
    cleanedValue = cleanedValue.substr(1)
  }
  const message = cleanedValue.length === 10 ? null : options.message
  callback(message)
}
