export const PROMOTION_PRICE = "30";
export const PROMOTION_DAYS = "30";
export const BENEFITS_REALTOR = [
    {
        title: "Get premium placement on Realtor.com",
        description: "Your listing will be placed among the top results for leads searching for rentals like yours.",
    },
    {
        title: "Get leads faster",
        description: "Increasing your exposure on Realtor.com will increase the likelihood of renters seeing your listing.",
    },
    {
        title: "30 days of promotion",
        description: "Your listing will have promotion status for 30 days or until you unpublish your listing",
    },
];
export const PROMOTION_PARTNER = "Realtor.com";
export var PromotedListingPartners;
(function (PromotedListingPartners) {
    PromotedListingPartners["RENTDOT_PREMIER"] = "rentdot_premier";
    PromotedListingPartners["ZUMPER_PREMIER"] = "zumper_premier";
    PromotedListingPartners["REALTOR_PREMIER"] = "realtor_premier";
})(PromotedListingPartners || (PromotedListingPartners = {}));
