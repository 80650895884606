import React from "react"
import PropTypes from "prop-types"
import { Facebook } from "react-feather"
import { unitType } from "types"
import { trackingAssist } from "utils"
import { TwitterX, Nextdoor } from "@rent_avail/icons"
import * as styles from "../ListingsStyles.scss"

const Threads = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="none"
    width="24"
    height="24"
    viewBox="0 0 16 16"
    strokeWidth="0.2"
    fill="currentColor"
  >
    <path d="M6.321 6.016c-.27-.18-1.166-.802-1.166-.802.756-1.081 1.753-1.502 3.132-1.502.975 0 1.803.327 2.394.948s.928 1.509 1.005 2.644q.492.207.905.484c1.109.745 1.719 1.86 1.719 3.137 0 2.716-2.226 5.075-6.256 5.075C4.594 16 1 13.987 1 7.994 1 2.034 4.482 0 8.044 0 9.69 0 13.55.243 15 5.036l-1.36.353C12.516 1.974 10.163 1.43 8.006 1.43c-3.565 0-5.582 2.171-5.582 6.79 0 4.143 2.254 6.343 5.63 6.343 2.777 0 4.847-1.443 4.847-3.556 0-1.438-1.208-2.127-1.27-2.127-.236 1.234-.868 3.31-3.644 3.31-1.618 0-3.013-1.118-3.013-2.582 0-2.09 1.984-2.847 3.55-2.847.586 0 1.294.04 1.663.114 0-.637-.54-1.728-1.9-1.728-1.25 0-1.566.405-1.967.868ZM8.716 8.19c-2.04 0-2.304.87-2.304 1.416 0 .878 1.043 1.168 1.6 1.168 1.02 0 2.067-.282 2.232-2.423a6.2 6.2 0 0 0-1.528-.161" />
  </svg>
)

const propTypes = {
  unit: unitType.isRequired,
  disabled: PropTypes.bool,
}

const defaultProps = {
  disabled: false,
}

const listingsUrl = (unitId) => `${window.location.origin}/l/${unitId}`
const nextdoorShareBody = (unitId) =>
  window.encodeURIComponent(`Check out my new rental listing on Avail! ${listingsUrl(unitId)}`)
const nextdoorShareUrl = (unitId) =>
  `https://nextdoor.com/sharekit/?source=Avail&body=${nextdoorShareBody(unitId)}`

const trackListingSocialShare = ({ type, unitId }) => {
  const analyticArgs = {
    Type: type,
    "Unit ID": unitId,
  }
  trackingAssist.trackEvent("Clicked on Listing Social Share", analyticArgs)
}

const SocialShare = ({ unit, disabled }) => (
  <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
    <a
      aria-label="Share on Facebook"
      target="_blank"
      rel="noreferrer noopener"
      disabled={disabled}
      href={!disabled ? unit.shareUrl("facebook") : undefined}
      className={[
        `${disabled ? "light-gray-500" : "navy-blue-500"}`,
        styles.shareButton,
      ].toClassName()}
      onClick={() => trackListingSocialShare({ type: "facebook", unitId: unit.id })}
    >
      <Facebook />
    </a>
    <a
      aria-label="Share on X"
      target="_blank"
      rel="noreferrer noopener"
      disabled={disabled}
      href={!disabled ? unit.shareUrl("twitter") : undefined}
      className={[
        `${disabled ? "light-gray-500" : "navy-blue-500"}`,
        styles.shareButton,
      ].toClassName()}
      onClick={() => trackListingSocialShare({ type: "X", unitId: unit.id })}
    >
      <TwitterX />
    </a>
    <a
      aria-label="Share on Threads"
      target="_blank"
      rel="noreferrer noopener"
      disabled={disabled}
      href={!disabled ? unit.shareUrl("threads") : undefined}
      className={[
        `${disabled ? "light-gray-500" : "navy-blue-500"}`,
        styles.shareButton,
      ].toClassName()}
      onClick={() => trackListingSocialShare({ type: "threads", unitId: unit.id })}
    >
      <Threads />
    </a>
    <a
      aria-label="Share on Nextdoor"
      target="_blank"
      rel="noreferrer noopener"
      disabled={disabled}
      href={!disabled ? nextdoorShareUrl(unit.id) : null}
      aria-disabled={disabled ? "true" : "false"}
      className={[
        `${disabled ? "light-gray-500" : "navy-blue-500"}`,
        styles.shareButton,
      ].toClassName()}
      onClick={() => trackListingSocialShare({ type: "nextdoor", unitId: unit.id })}
    >
      <Nextdoor />
    </a>
  </div>
)

SocialShare.propTypes = propTypes
SocialShare.defaultProps = defaultProps

export default SocialShare
