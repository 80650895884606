import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Flex, Stack } from "@rent_avail/core";
import { Text } from "@rent_avail/typography";
import { trackingAssist } from "utils";
import { useDisplayPromotedListing } from "../../../../hooks/useDisplayPromotedListing";
import { PurchasePromotedStatusDialog } from "./purchase";
import { PurpleButton } from "./purchase/PurpleButton";
import PromoteListingBannerTitle from "./PromoteListingBannerTitle";
export const PROMOTED_LISTING_QUERY_PARAM = "promoted_listing";
export const PromoteListingBanner = ({ onPurchase }) => {
    const [isPurchaseDialogVisible, setIsPurchaseDialogVisible] = useState(false);
    const { id: unitId } = useParams();
    const { loading, shouldDisplayPromotedListing } = useDisplayPromotedListing(unitId);
    const location = useLocation();
    const history = useHistory();
    const queryParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    useEffect(() => {
        var _a;
        const showPurchaseDialog = queryParams.has(PROMOTED_LISTING_QUERY_PARAM);
        if (((_a = location.state) === null || _a === void 0 ? void 0 : _a.showPurchaseDialog) || showPurchaseDialog) {
            setIsPurchaseDialogVisible(true);
            queryParams.delete(PROMOTED_LISTING_QUERY_PARAM);
            history.replace({ ...location, state: {}, search: queryParams.toString() });
        }
    }, [location, history, queryParams]);
    const handleClose = () => {
        queryParams.delete(PROMOTED_LISTING_QUERY_PARAM);
        history.replace({
            ...location,
            search: queryParams.toString(),
        });
        setIsPurchaseDialogVisible(false);
    };
    const handleLearnMore = () => {
        trackingAssist.trackEvent("Promoted Listing Clicked", {
            "Modal Version": "Learn More",
            "Unit ID": unitId,
            Type: "Self Serve",
        });
        setIsPurchaseDialogVisible(true);
    };
    if (loading)
        return null;
    if (!shouldDisplayPromotedListing) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Flex, { as: "section", "aria-labelledby": "promoted-listings-title", sx: {
                alignItems: "center",
                bg: "purple_100",
                flexWrap: ["wrap", "wrap", null],
                gap: 2,
                justifyContent: "space-between",
                ml: [-1, -1, 0, 0],
                mr: [-1, -1, 0, 0],
                mb: -2,
                p: 2,
            } },
            React.createElement(Stack, { sx: { gap: "0.667rem", maxWidth: "50rem" } },
                React.createElement(PromoteListingBannerTitle, null),
                React.createElement(Text, { fontSize: "1.334rem" },
                    React.createElement("strong", null, "Rent your place faster."),
                    " Boost your exposure by getting top placement on",
                    " ",
                    "search results.")),
            React.createElement(PurpleButton, { "aria-haspopup": "dialog", "aria-controls": "purchase-promoted-status-dialog-id", "aria-expanded": isPurchaseDialogVisible ? "true" : "false", width: ["100%", "100%", "auto"], onClick: handleLearnMore }, "Learn More")),
        React.createElement(PurchasePromotedStatusDialog, { id: "purchase-promoted-status-dialog-id", isVisible: isPurchaseDialogVisible, onClose: handleClose, onPurchase: onPurchase })));
};
