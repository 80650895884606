import React from "react";
import { Text } from "@rent_avail/typography";
import { Box, Flex } from "@rent_avail/core";
import { lightGray300 } from "theme";
const PromotedListingsHowItWorksListItem = ({ icon: Icon, title, content, }) => (React.createElement(Flex, { as: "li", sx: {
        gap: 1,
        position: "relative",
        "::after": {
            content: '""',
            position: "absolute",
            top: "4rem",
            left: "1.5rem",
            width: "2px",
            height: "calc(100% - 1.5rem)",
            backgroundColor: lightGray300,
            transform: "translateX(-50%)",
        },
        "&:last-child::after": {
            content: "none",
        },
    } },
    React.createElement(Flex, { sx: {
            alignItems: "center",
            flexShrink: 0,
            flexGrow: 0,
            justifyContent: "center",
            bg: lightGray300,
            borderRadius: "50%",
            color: "purple_700",
            height: "3rem",
            width: "3rem",
        } },
        React.createElement(Icon, { size: 24 })),
    React.createElement(Box, null,
        React.createElement(Text, { color: "black", fontSize: "1.5rem", fontWeight: 700 }, title),
        React.createElement(Text, { color: "black", fontSize: "1.3rem", fontWeight: 400 }, content))));
export default PromotedListingsHowItWorksListItem;
