import React, { useState } from "react";
import { Dialog, DialogHeader } from "@rent_avail/dialog";
import { Heading, Text } from "@rent_avail/typography";
import { Box, Stack, Flex, Grid, Col } from "@rent_avail/core";
import { Check, Zap } from "@rent_avail/icons";
import { useParams } from "react-router-dom";
import { trackingAssist } from "utils";
import DialogContent from "../../stepper/Profile/PreviewDialog/Container";
import { PurchasePromotedStatusDialog } from "./PurchasePromotedStatusDialog";
import { PurchaseBenefits } from "./PurchaseBenefits";
import { PurpleButton } from "./PurpleButton";
const BENEFITS = [
    {
        title: "Get premium placement on Realtor.com",
        description: "Your listing will be placed among the top results for leads searching for rentals like yours.",
    },
    {
        title: "Get leads faster",
        description: "Increasing your exposure on Realtor.com will increase the likelihood of renters seeing your listing.",
    },
];
export const PublishingPromotedDialog = ({ isVisible, onClose, onPurchase }) => {
    const [isPurchaseDialogVisible, setIsPurchaseDialogVisible] = useState(false);
    const { id: unitId } = useParams();
    const showPurchaseDialog = () => {
        onClose();
        setIsPurchaseDialogVisible(true);
    };
    const handlePromotedListingClicked = () => {
        trackingAssist.trackEvent("Promoted Listing Clicked", {
            "Modal Version": "Listing Published",
            "Unit ID": unitId,
            Type: "Self Serve",
        });
        showPurchaseDialog();
    };
    const hidePurchaseDialog = () => setIsPurchaseDialogVisible(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: isVisible, toggle: onClose },
            React.createElement(DialogContent, { sx: {
                    zIndex: 3,
                    "& > aside": { maxWidth: "75rem", overflow: "hidden", p: 0 },
                    padding: 0,
                } },
                React.createElement(Grid, null,
                    React.createElement(Col, { sx: { bg: "green_100" } },
                        React.createElement(Stack, { sx: { alignItems: "center", gap: 1, p: 2, width: "100%" } },
                            React.createElement(DialogHeader, { sx: { m: 0, mb: "0!important", ml: "auto" } }),
                            React.createElement(Box, { sx: {
                                    color: "green_900",
                                    bg: "green_700",
                                    borderRadius: "50%",
                                    lineHeight: 0,
                                    p: 1,
                                } },
                                React.createElement(Check, { size: 24 })),
                            React.createElement(Heading, { as: "h4", fontWeight: "700", lineHeight: "1.5" }, "Your listing is submitted!"),
                            React.createElement(Text, { textAlign: "center", maxWidth: "54rem" },
                                "We are reviewing your listing. It may take up to ",
                                React.createElement("strong", null, "12 hours"),
                                " before your listing is live on our network of partner sites. We will email you once your listing review is complete and published."))),
                    React.createElement(Col, null,
                        React.createElement(Stack, { sx: {
                                display: "flex",
                                gap: 1,
                                maxWidth: "48rem",
                                width: "100%",
                                m: "0 auto",
                                p: 2,
                            } },
                            React.createElement(Text, { fontSize: "1.334rem" },
                                React.createElement("strong", null, "Need leads fast?"),
                                " Get more from your listing with promoted listing"),
                            React.createElement(Stack, { sx: { gap: 2, p: 2, bg: "purple_100", borderRadius: "4px" } },
                                React.createElement(Stack, { sx: { gap: 1 } },
                                    React.createElement(Flex, { sx: { gap: "0.75rem", alignItems: "center" } },
                                        React.createElement(Box, { as: Zap, size: 19, sx: { color: "purple_700", fill: "currentColor" } }),
                                        React.createElement(Heading, { as: "h4", fontWeight: "700" }, "Promoted Listings")),
                                    React.createElement(PurchaseBenefits, { items: BENEFITS, sx: { gap: 1 } })),
                                React.createElement(PurpleButton, { "aria-haspopup": "dialog", "aria-controls": "purchase-promoted-status-dialog-id", "aria-expanded": showPurchaseDialog ? "true" : "false", type: "button", variant: "primary", width: "100%", onClick: handlePromotedListingClicked }, "Learn More")),
                            React.createElement(Text, { as: "a", href: "#", color: "blue_500", fontWeight: "700", m: "0 auto", mt: "1rem", style: { textDecoration: "underline" }, onClick: (e) => {
                                    e.preventDefault();
                                    onClose();
                                } }, "Maybe later")))))),
        React.createElement(PurchasePromotedStatusDialog, { id: "purchase-promoted-status-dialog-id", isVisible: isPurchaseDialogVisible, onClose: hidePurchaseDialog, onPurchase: onPurchase })));
};
